import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";

export type HeaderImageProps = {
  readonly categories: readonly (string | null)[] | null;
  readonly headerImage: IGatsbyImageData | null | undefined;
  readonly subheading: string | null;
  readonly title: string | null;
};

export function PortfolioPageHeader(props: HeaderImageProps) {
  const categories =
    props.categories?.filter((category): category is string =>
      Boolean(category)
    ) ?? [];

  const image = props.headerImage;
  return (
    <header className="max-h-[50vh] bg-neutral-200 grid grid-cols-1 grid-rows-1 content-center justify-center">
      {image && props.title && (
        <GatsbyImage
          image={image}
          alt={props.title}
          className="col-start-1 row-start-1"
        />
      )}

      <div className="z-10 col-start-1 row-start-1 grid content-center justify-center">
        <div className="bg-neutral-100 text-center p-8">
          <h2 className="font-bold text-xl lg:text-3xl antialiased font-condensed">
            {props.subheading}
          </h2>

          <h1 className="uppercase font-light text-2xl md:text-4xl antialiased my-4 md:my-6">
            {props.title}
          </h1>

          <p className="text-xs md:text-lg">{categories.join(" · ")}</p>
        </div>
      </div>
    </header>
  );
}
