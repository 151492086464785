import clsx from "clsx";
import React from "react";

export function ContentContainer(props: {
  children: React.ReactNode;
  className?: string;
  size?: "narrow" | "wide" | "full-bleed";
}) {
  return (
    <section
      className={clsx([
        "mx-auto my-16 px-4 lg:px-0",
        props.className,
        props.size === "narrow"
          ? "container max-w-xl"
          : props.size === "wide"
          ? "container w-screen"
          : props.size === "full-bleed"
          ? "w-screen px-0"
          : "container max-w-2xl",
      ])}
    >
      {props.children}
    </section>
  );
}
