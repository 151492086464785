import { getImage } from "gatsby-plugin-image";

export function getGatsbyImage(
  images: Queries.getPortfolioPageDataQuery["images"]["nodes"],
  contentImageSrc: string | null | undefined
) {
  const imageData = images.find((node) => node.filename === contentImageSrc)
    ?.childImageSharp?.gatsbyImageData;

  const image = imageData ? getImage(imageData) : null;
  return image;
}
