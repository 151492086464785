import clsx from "clsx";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { ContentContainer } from "../components/ContentContainer";
import Layout from "../components/Layout";
import { PortfolioPageHeader } from "../components/PortfolioPageHeader";
import { getGatsbyImage } from "../utils";

function TextBlock(props: { children: string; className?: string }) {
  return (
    <p
      className={clsx("antialiased text-lg text-neutral-800", props.className)}
    >
      {props.children}
    </p>
  );
}

export default function PortfolioPage(
  props: PageProps<Queries.getPortfolioPageDataQuery>
) {
  const page = props.data.page;
  const contents = page?.contents?.filter((content) => Boolean(content)) ?? [];
  const images = props.data.images.nodes;
  const pageId = props.data.page?.id;

  const headerImage = getGatsbyImage(images, page?.headerImage);

  const categories = page?.categories?.filter((category): category is string =>
    Boolean(category)
  );

  return (
    <Layout>
      {page?.title && categories?.length && headerImage && (
        <PortfolioPageHeader
          title={page.title}
          subheading={page.subheading}
          categories={categories}
          headerImage={headerImage}
        />
      )}

      {contents?.map((content, index) => {
        if (content?.type === "heading" && content?.heading) {
          return (
            <ContentContainer key={`content-section-${index}-${page?.id}`}>
              <h2 className="antialiased text-xl text-center">
                {content?.heading}
              </h2>
            </ContentContainer>
          );
        }
        if (content?.type === "text" && content?.text) {
          return (
            <ContentContainer key={`content-section-${index}-${page?.id}`}>
              <TextBlock>{content.text}</TextBlock>
            </ContentContainer>
          );
        }
        if (content?.type === "image" && content.src && content.text) {
          const contentImage = getGatsbyImage(images, content?.src);
          return (
            <ContentContainer
              key={`content-section-${index}-${page?.id}`}
              size="wide"
            >
              {contentImage && (
                <GatsbyImage
                  alt={content.text}
                  image={contentImage}
                  className="bg-white"
                />
              )}
              <span className="block text-center my-4 text-sm font-light">
                {content.text}
              </span>
            </ContentContainer>
          );
        }

        return null;
      })}
    </Layout>
  );
}

export const pageQuery = graphql`
  query getPortfolioPageData($slug: String!) {
    page: portfolioPage(slug: { eq: $slug }) {
      id
      categories
      headerImage
      contents {
        type
        heading
        src
        text
      }
      slug
      subheading
      text
      title
    }
    images: allFile(
      filter: {
        relativeDirectory: { eq: $slug }
        extension: { in: ["png", "jpg"] }
      }
    ) {
      nodes {
        relativePath
        relativeDirectory
        id
        publicURL
        sourceInstanceName
        name
        filename: base
        childImageSharp {
          id
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`;
